import React from "react"

const Message = ({ type, message }) => {
  if (type) {
    return <div className={`text-center alert alert-${type}`}>{message}</div>
  } else {
    return null
  }
}

export default Message
